import store from '@/state.js'
import router from '@/router.js'

var md5 = require('md5')
var cooldown = 5000
var ws
const currentConfigVersion = '1'

// disables the "websocket disconnected" toast caused by the websocket closing before the page unloads when refreshing or clicking a link
window.onbeforeunload = function () {
    ws.onclose = function () {}
    ws.close()
}

async function request (method, path, data) {
    const request = {
        method: method,
        credentials: 'same-origin',
    }

    if (method === 'POST' || method === 'PATCH') {
        const headers = { 'Content-Type': 'application/json' }
        const name = 'csrftoken='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                headers['X-CSRFToken'] = c.substring(name.length, c.length)
            }
        }

        request.body = JSON.stringify(data)
        request.headers = headers
    }

    const r = await fetch(path, request)

    return r
}

async function updateConfig () {
    const r = (await request('GET', '/api/config'))

    const data = await r.text()
    const parsed = JSON.parse(data)

    // if websocket was not enabled but its supposed to be, init websocket
    // TODO disconnect websocket in the opposite case
    let config = null
    try {
        config = JSON.parse(localStorage.getItem('config'))
    } catch {}
    if ((!config || !config.websocket) && parsed.websocket) {
        await initWebsocket()
    }

    localStorage.setItem('config', data)
    localStorage.setItem('configVersion', currentConfigVersion)
    store.commit('processConfig', parsed)

    return parsed
}

async function watsup () {
    const r = await request('GET', '/api/watsup')
    let data = {}
    try {
        data = await r.json()
    } catch {}

    if (r.status === 200) {
        // process data
        data.challenges.forEach(c => {
            store.commit('processChallenge', c)
        })
        store.commit('rebuildCategories')
        data.announcements.forEach(a => {
            store.commit('processAnnouncement', a)
        })
        store.commit('updateUser', data.user)
        store.commit('updateUserTeam', data.team)
        if (data.scoreboard_frozen) {
            store.commit('freezeScoreboard', data.scoreboard_freeze_time)
        }
        store.commit('watsupReturned')

        // update config if outdated
        const configstr = localStorage.getItem('config')
        if (md5(configstr) !== data.config_hash) {
            await updateConfig()
        }
    } else {
        if (store.config.websockets) {
            ws.close() // very hacky solution, kills the websocket when watsup fails so websocket reconnects and redoes watsup, putting a bit of unnecesary strain on the backend. go ahead an implement something better if you want, i couldn't be bothered
        } else {
            store.commit('createToast', {
                type: 'error',
                title: `Backend could not be reached: ${r.status}`,
                body: 'The page may contain outdated information and some content may not load. Reload the page to try again.',
                time: 100000000000,
            })
        }
    }
}

async function handleMessage (event) {
    const message = JSON.parse(event.data)
    switch (message.type) {
    case 'flag':
        // update challenge
        // update dynamic scores
        // update team
        // sort scoreboard

        store.commit('processChallenge', message.challenge)
        // dynamic scoring needs to be processed before updating the team to not mess up the solving teams score
        if (message.challenge.score === null && store.state.scoreboardLoaded) {
            store.commit('processDynamicSolve', message)
        }

        // if scoreboard is frozen make sure to not update the team instance on the scoreboard, updateUserTeam doesn't do that
        if (!store.state.scoreboardFrozen || (store.state.team && message.team.id !== store.state.team.id)) {
            store.commit('updateTeam', message.team)
        } else {
            store.commit('updateUserTeam', message.team)
        }

        store.commit('sortScoreboard')
        break
    case 'challenge':
        for (const i in message.challenges) {
            store.commit('processChallenge', message.challenges[i])
        }
        store.commit('rebuildCategories')
        break
    case 'rating':
        // TODO ratings
        break
    case 'announcement':
        store.commit('processAnnouncement', message.announcement)
        {
            const n = new Notification(message.announcement.title, {
                body: message.announcement.description,
                renotify: true,
            })// TODO set icon to favicon
            n.onclick = function () {
                router.replace({ name: 'announcements' })
            }
            document.addEventListener('visibilitychange', function () {
                if (document.visibilityState === 'visible') {
                    n.close()
                }
            })
        }
        break
    case 'update_team':
        store.commit('updateTeam', message.team)
        break
    case 'freeze':
        store.commit('freezeScoreboard', message.time)
        break
    case 'unfreeze':
        store.commit('unfreezeScoreboard')
        store.commit('insertScoreboard', message.scoreboard)
        break
    default:
        console.error('unknown message type recieved', message)
    }
}

async function initWebsocket () {
    const protocol = (location.protocol === 'https:') ? 'wss://' : 'ws://'
    const host = location.hostname
    const port = (location.port) ? `:${location.port}` : ''

    ws = new WebSocket(`${protocol}${host}${port}/ws/main`)

    ws.onopen = function (event) {
        if (store.state.connected === false) {
            store.commit('createToast', {
                type: 'success',
                title: 'Websocket connected',
            })
        }
        store.commit('setConnected', true)
        cooldown = 5000
        watsup()
    }
    ws.onclose = function (event) {
        store.commit('setConnected', false)
        store.commit('createToast', {
            type: 'error',
            title: 'Websocket disconnected',
            body: `Attempting to reconnect in ${cooldown / 1000}s`,
            time: cooldown,
        })
        setTimeout(initWebsocket, cooldown)
        cooldown = Math.min(60000, cooldown * 2)
    }
    ws.onmessage = handleMessage
}

async function init () {
    const configstr = localStorage.getItem('config')

    let config
    // attempt to fetch config
    try {
        config = JSON.parse(configstr)
        store.commit('processConfig', config)
    } catch { // if config is invalid json, fetch a new config
        config = await updateConfig()
    }

    const configVersion = localStorage.getItem('configVersion')
    // if a new frontend was pushed incompatible with older configs, fetch a new config
    if (configVersion !== currentConfigVersion) {
        config = await updateConfig()
    }

    if (store.state.websockets) {
        await initWebsocket()
    } else {
        await watsup()
    }
}

async function ensureScoreboard (forceUnfrozen) {
    if (store.state.scoreboardLoading) return
    store.commit('setScoreboardLoading', true)
    const r = await request('GET', forceUnfrozen ? '/api/scoreboard?force_unfrozen=1' : '/api/scoreboard')
    if (r.status === 200) {
        store.commit('insertScoreboard', await r.json())
        store.commit('setScoreboardLoaded', true)
        if (forceUnfrozen) {
            store.commit('createToast', {
                type: 'success',
                title: 'Loaded unfrozen scoreboard',
            })
        }
    } else {
        store.commit('createToast', {
            type: 'error',
            title: `Scoreboard could not be loaded: ${r.status}`,
            body: 'Reload the page to attempt to load the scoreboard again.',
            time: 20000,
        })
    }
}

async function reloadScoreboard (forceUnfrozen) {
    store.commit('setScoreboardLoading', false)
    await ensureScoreboard(forceUnfrozen)
}

export default {
    request, init, ensureScoreboard, reloadScoreboard,
}
