<template>
  <div class="logo-container">
    <div class="logo">
      <img src="@/assets/logo.svg" alt="">
      <span>Säkerhets-SM 2025 Kval</span>
    </div>
  </div>
  <ClockTimeline />
  <div class="button-container">
    <router-link
      v-if="!$store.state.archived"
      :to="{ name: 'signin' }"
    >
      <button class="button">
        Register!
      </button>
    </router-link>
  </div>
  <div class="content">
    <div>
      <h3>Description</h3>
      <p>
        Säkerhets-SM is a national Capture The Flag competition for high schools students. The competition focuses on problem solving in the areas of programming, cryptography, binary exploitation, reverse engineering, web security and forensics. The tasks range from beginner-friendly to really tricky, so even if you've never participated in a CTF before, you can take part. Above all, you will learn a lot from participating.
      </p>
      <p>
        CTF (Capture The Flag) competitions involve getting access to a set of challenges at the start of the competition and demonstrating that you have solved a task by submitting the task's "flag" to the platform. Flags in this competition are text strings of the form <code>SSM{...}</code>.
      </p>
      <p>
        This year's competition consists of a qualifying round and a final. The qualifying round will take place here from January 17th 20:00 CET to January 19th 20:00 CET. The competition will thus last for 48 hours, but it is already possible to register your team!
      </p>
      <p>
        Some of the challenges in the qualifying round are marked as easy. We recommend you to start with them if you haven't played a CTF competition before! We also allow you to ask the organizers for clues on these easy challenges, so don't hesitate to send a message to the challenge author via <a href="https://discord.gg/m6pcEYp">Discord</a> or post a message in #säkerhetssm if you get stuck!
      </p>
      <h3>Final</h3>
      <p>
        The best teams qualify for the final which this year is held at KTH in Stockholm! This will take place March 7th-9th, and we pay for any travel, accommodation and food costs. The qualification cutoff will be decided after the qualification round has concluded, with the intention of inviting around 45 players.
      </p>
    </div>
    <div>
      <h3>Rules</h3>
      <ul>
        <li>In order for a team to be in the competing class, the team must have qualified from the qualifying round or have been invited by the organizers.</li>
        <li>If any of the requirements in the previous rule are not met, you can still participate in the competition in the open class.</li>
        <li>It is forbidden to be a member of multiple teams simultaneously.</li>
        <li>Unless otherwise explicitly stated, it is forbidden to discuss problem solutions and clues with individuals outside of your team while the competition is ongoing.</li>
        <li>It is allowed to search the internet, read discussions on forums, and use previously written code, but not to ask questions yourself during the competition. It is not allowed, for example, to publicly ask about the tasks in Discord.</li>
        <li>It is allowed to use AI tools such as ChatGPT or GitHub Copilot.</li>
        <li>It is forbidden to attack the CTF platform. This includes brute-forcing flags against the competition platform.</li>
        <li>
          Respect the challenge servers. Do not create unreasonably large traffic to the challenges. No tasks require sending an unreasonable amount of data to get the flag. <div class="tooltip">
            ?<span class="tooltiptext">What counts as an unreasonable amount of data?<br><br>If you see a solution to a task that requires a few thousand requests, this is completely okay to perform. If your solution requires tens or hundreds of thousands of requests, your solution is probably wrong, and you should find a better solution to not affect the server's performance while solving. It is not okay to blindly spam the server with data, for example, through a dumb scanner like dirbuster, unless it is stated in the task that it is allowed.</span>
          </div>
        </li>
        <li>It is forbidden to disrupt other people's ability to solve challenges.</li>
        <li>It is forbidden to have more than one account on the CTF platform.</li>
        <li>It is forbidden to use the platform for commercial marketing purposes.</li>
        <li>This is a competition in ethical hacking, take responsibility!</li>
        <li>The decisions of the organizers are final.</li>
      </ul>
    </div>
  </div>
  <div class="centeredcontent">
    <h3>Discord</h3>
    <p>
      Join Kodsport's <a href="https://discord.gg/m6pcEYp">Discord server</a> to discuss the competition with others in the #säkerhetssm channel! Remember that it's not allowed to discuss the problems while the competition is ongoing! If you don't have any teammates or if your team isn't full, you can also send a message in the #leta-lag channel to find people to play with. In the Discord, you'll also find all the organizers, admins, and challenge authors in case you need to contact them; they have the role @Säkerhets-SM.
    </p>
  </div>
  <h3>I samarbete med</h3>
  <div class="mainsponsorcontainer">
    <a href="https://www.fra.se/">
      <img
        class="mainsponsor"
        src="@/assets/sponsors/fra.png"
        alt="FRA - Försvarets Radioanstalt"
      >
    </a>
  </div>
  <p style="text-align: center">
    Kodsport's infrastructure is sponsored by <a href="https://glesys.com/">GleSYS</a>
  </p>
</template>

<script>
import ClockTimeline from '@/components/ClockTimeline.vue'

export default {
    name: 'HomeView',
    components: {
        ClockTimeline,
    },
}
</script>

<style lang="scss" scoped>
.logo-container {
  .logo {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    max-width: 50rem;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      padding: 0 1rem;
      word-wrap: normal;
    }
  }
}

h1, h3 {
  text-align: center;
}

p {
  padding: 0 10px 0 10px;
}

.button-container {
  margin-top: 2rem;
  text-align: center;
}

.button {
  padding: 0.6rem 1rem;
  font-size: 2rem;
  color: #ffab1c;
}

.content {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 3rem;
}

.centeredcontent {
  width: 50%;
  margin: 0 auto 3rem auto;
}

.mainsponsorcontainer {
  text-align: center;
  margin-top: 2rem;
}

.mainsponsor {
  max-width: 100%;
  max-height: 350px;
  padding-bottom: 1rem;
}

.sponsorcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sponsor {
  max-width: 90%;
  max-height: 100px;
  margin: 1rem 1rem;
}

@media only screen and (max-width: 990px) {
  .content {
    display: block;
  }

  .centeredcontent {
    width: auto;
  }
}
</style>
